export default function PrivacyPolicy() {
    return <div className="text-white flex flex-col space-y-4 justify-start items-center mt-48 w-full h-full">
        <h1 className="font-bold text-3xl"> Privacy Policy</h1>
        <div className="w-1/2 ">

            {`PRIVACY POLICY

Last updated January 03, 2025

This Privacy Notice for Forerunner AI Inc. ("we," "us," or "our"), describes

how and why we might access, collect, store, use, and/or share

("process") your personal information when you use our services

("Services"), including when you:

Visit our website at tryforerunner.com, or any website of ours that

links to this Privacy Notice

Engage with us in other related ways, including any sales,

marketing, or events

Questions or concerns? Reading this Privacy Notice will help you

understand your privacy rights and choices. We are responsible for making

decisions about how your personal information is processed. If you do not

agree with our policies and practices, please do not use our Services. If

you still have any questions or concerns, please contact us at

yash@tryforerunner.com.

SUMMARY OF KEY POINTS

This summary provides key points from our Privacy Notice, but you

can find out more details about any of these topics by clicking the

link following each key point or by using our table of contents below

to find the section you are looking for.

What personal information do we process? When you visit, use, or

navigate our Services, we may process personal information depending on

how you interact with us and the Services, the choices you make, and the

products and features you use. Learn more about personal information you

disclose to us.

Do we process any sensitive personal information? Some of the

information may be considered "special" or "sensitive" in certain

jurisdictions, for example your racial or ethnic origins, sexual orientation,

and religious beliefs. We do not process sensitive personal information.

Do we collect any information from third parties? We do not collect any

information from third parties.


How do we process your information? We process your information to

provide, improve, and administer our Services, communicate with you, for

security and fraud prevention, and to comply with law. We may also

process your information for other purposes with your consent. We process

your information only when we have a valid legal reason to do so. Learn

more about how we process your information.

In what situations and with which parties do we share personal

information? We may share information in specific situations and with

specific third parties. Learn more about when and with whom we share

your personal information.

What are your rights? Depending on where you are located

geographically, the applicable privacy law may mean you have certain

rights regarding your personal information. Learn more about your privacy

rights.

How do you exercise your rights? The easiest way to exercise your

rights is by visiting tryforerunner.com, or by contacting us. We will consider

and act upon any request in accordance with applicable data protection

laws.

Want to learn more about what we do with any information we

collect? Review the Privacy Notice in full.
\n\n
TABLE OF CONTENTS
\n
1. WHAT INFORMATION DO WE COLLECT?
\n
2. HOW DO WE PROCESS YOUR INFORMATION?
\n
3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
\n
INFORMATION?
\n
4. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
\n
5. HOW LONG DO WE KEEP YOUR INFORMATION?
\n
6. DO WE COLLECT INFORMATION FROM MINORS?
\n
7. WHAT ARE YOUR PRIVACY RIGHTS?
\n
8. CONTROLS FOR DO-NOT-TRACK FEATURES
\n
9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
RIGHTS?
\n
10. DO WE MAKE UPDATES TO THIS NOTICE?
\n
11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
\n
12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
COLLECT FROM YOU?
\n\n
1. WHAT INFORMATION DO WE COLLECT?

Personal information you disclose to us

In Short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you

express an interest in obtaining information about us or our products and

Services, when you participate in activities on the Services, or otherwise

when you contact us.

Sensitive Information. We do not process sensitive information.

All personal information that you provide to us must be true, complete, and

accurate, and you must notify us of any changes to such personal

information.

Information automatically collected

In Short: Some information — such as your Internet Protocol (IP) address

and/or browser and device characteristics — is collected automatically

when you visit our Services.

We automatically collect certain information when you visit, use, or

navigate the Services. This information does not reveal your specific


identity (like your name or contact information) but may include device and

usage information, such as your IP address, browser and device

characteristics, operating system, language preferences, referring URLs,

device name, country, location, information about how and when you use

our Services, and other technical information. This information is primarily

needed to maintain the security and operation of our Services, and for our

internal analytics and reporting purposes.

The information we collect includes:

Log and Usage Data. Log and usage data is service-related,

diagnostic, usage, and performance information our servers

automatically collect when you access or use our Services and

which we record in log files. Depending on how you interact with us,

this log data may include your IP address, device information,

browser type, and settings and information about your activity in the

Services (such as the date/time stamps associated with your usage,

pages and files viewed, searches, and other actions you take such

as which features you use), device event information (such as

system activity, error reports (sometimes called "crash dumps"), and

hardware settings).

2. HOW DO WE PROCESS YOUR INFORMATION?

In Short: We process your information to provide, improve, and administer

our Services, communicate with you, for security and fraud prevention, and

to comply with law. We may also process your information for other

purposes with your consent.

We process your personal information for a variety of reasons,

depending on how you interact with our Services, including:

To deliver and facilitate delivery of services to the user. We may

process your information to provide you with the requested service.

3. WHEN AND WITH WHOM DO WE SHARE YOUR

PERSONAL INFORMATION?

In Short: We may share information in specific situations described in this

section and/or with the following third parties.

We may need to share your personal information in the following situations:

Business Transfers. We may share or transfer your information in

connection with, or during negotiations of, any merger, sale of

company assets, financing, or acquisition of all or a portion of our

business to another company.

4. DO WE OFFER ARTIFICIAL INTELLIGENCE-

BASED PRODUCTS?

In Short: We offer products, features, or tools powered by artificial

intelligence, machine learning, or similar technologies.

As part of our Services, we offer products, features, or tools powered by

artificial intelligence, machine learning, or similar technologies (collectively,

"AI Products"). These tools are designed to enhance your experience and

provide you with innovative solutions. The terms in this Privacy Notice

govern your use of the AI Products within our Services.

Use of AI Technologies

We provide the AI Products through third-party service providers ("AI

Service Providers"), including Amazon Web Services (AWS) AI, Google

Cloud AI, Microsoft Azure AI, Anthropic and OpenAI. As outlined in this



Privacy Notice, your input, output, and personal information will be shared

with and processed by these AI Service Providers to enable your use of our

AI Products for purposes outlined in "WHEN AND WITH WHOM DO WE

SHARE YOUR PERSONAL INFORMATION?" You must not use the AI

Products in any way that violates the terms or policies of any AI Service

Provider.

Our AI Products

Our AI Products are designed for the following functions:

AI document generation

AI applications

AI bots

AI search

Natural language processing

How We Process Your Data Using AI

All personal information processed using our AI Products is handled in line

with our Privacy Notice and our agreement with third parties. This ensures

high security and safeguards your personal information throughout the

process, giving you peace of mind about your data's safety.

5. HOW LONG DO WE KEEP YOUR INFORMATION?

In Short: We keep your information for as long as necessary to fulfill the

purposes outlined in this Privacy Notice unless otherwise required by law.

We will only keep your personal information for as long as it is necessary

for the purposes set out in this Privacy Notice, unless a longer retention

period is required or permitted by law (such as tax, accounting, or other

legal requirements).

When we have no ongoing legitimate business need to process your

personal information, we will either delete or anonymize such information,

or, if this is not possible (for example, because your personal information

has been stored in backup archives), then we will securely store your

personal information and isolate it from any further processing until deletion

is possible.

6. DO WE COLLECT INFORMATION FROM

MINORS?

In Short: We do not knowingly collect data from or market to children

under 18 years of age.

We do not knowingly collect, solicit data from, or market to children under

18 years of age, nor do we knowingly sell such personal information. By

using the Services, you represent that you are at least 18 or that you are

the parent or guardian of such a minor and consent to such minor

dependent’s use of the Services. If we learn that personal information from

users less than 18 years of age has been collected, we will deactivate the

account and take reasonable measures to promptly delete such data from

our records. If you become aware of any data we may have collected from

children under age 18, please contact us at yash@tryforerunner.com.

7. WHAT ARE YOUR PRIVACY RIGHTS?

In Short: You may review, change, or terminate your account at any time,

depending on your country, province, or state of residence.

Withdrawing your consent: If we are relying on your consent to process

your personal information, which may be express and/or implied consent

depending on the applicable law, you have the right to withdraw your

consent at any time. You can withdraw your consent at any time by

contacting us by using the contact details provided in the section "HOW

CAN YOU CONTACT US ABOUT THIS NOTICE?" below.

However, please note that this will not affect the lawfulness of the

processing before its withdrawal nor, when applicable law allows, will it

affect the processing of your personal information conducted in reliance on

lawful processing grounds other than consent.

If you have questions or comments about your privacy rights, you may

email us at yash@tryforerunner.com.

8. CONTROLS FOR DO-NOT-TRACK FEATURES

Most web browsers and some mobile operating systems and mobile

applications include a Do-Not-Track ("DNT") feature or setting you can

activate to signal your privacy preference not to have data about your

online browsing activities monitored and collected. At this stage, no uniform

technology standard for recognizing and implementing DNT signals has

been finalized. As such, we do not currently respond to DNT browser

signals or any other mechanism that automatically communicates your

choice not to be tracked online. If a standard for online tracking is adopted

that we must follow in the future, we will inform you about that practice in a

revised version of this Privacy Notice.

California law requires us to let you know how we respond to web browser

DNT signals. Because there currently is not an industry or legal standard

for recognizing or honoring DNT signals, we do not respond to them at this

time.

9. DO UNITED STATES RESIDENTS HAVE SPECIFIC

PRIVACY RIGHTS?

Dashboard

Consent Management

Policies

In Short: If you are a resident of California, Colorado, Connecticut,

Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana,

Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah,

You have surpassed your 1 policy limit. Please upgrade to publish additional policies. or Virginia, you may have the right to request access to and receive details

Learn More

about the personal information we maintain about you and how we have

processed it, correct inaccuracies, get a copy of, or delete your personal

information. You may also have the right to withdraw your consent to our

processing of your personal information. These rights may be limited in

some circumstances by applicable law. More information is provided below.

Privacy Policy

Cookie Policy

Terms and Conditions

EULA

Return Policy

Disclaimer

Shipping Policy

Acceptable Use Policy

Settings

Categories of Personal Information We Collect

We have collected the following categories of personal information in the

past twelve (12) months:

Category Examples Collected

A. Identifiers

Contact details, such as real name,

alias, postal address, telephone or

mobile contact number, unique

personal identifier, online identifier,

Internet Protocol address, email

address, and account name

NO

B. Personal information

as defined in the

California Customer

Name, contact information,

education, employment, employment

history, and financial information

NO

Records statute

C. Protected

classification

Gender, age, date of birth, race and

ethnicity, national origin, marital NO


characteristics under

status, and other demographic data

state or federal law

D. Commercial

information

Transaction information, purchase

history, financial details, and payment

information

E. Biometric information Fingerprints and voiceprints NO

NO

Browsing history, search history,

online behavior, interest data, and

F. Internet or other

interactions with our and other

similar network activity

websites, applications, systems, and

advertisements

G. Geolocation data Device location NO

NO

H. Audio, electronic,

sensory, or similar

information

Images and audio, video or call

recordings created in connection with

our business activities

I. Professional or

employment-related

information

Business contact details in order to

provide you our Services at a

business level or job title, work

history, and professional

qualifications if you apply for a job

with us

J. Education Information Student records and directory

information

Inferences drawn from any of the

collected personal information listed

above to create a profile or summary

about, for example, an individual’s

preferences and characteristics

NO

NO

NO

K. Inferences drawn

from collected personal

information

NO

L. Sensitive personal

Information

NO

We may also collect other personal information outside of these categories

through instances where you interact with us in person, online, or by phone

or mail in the context of:

Receiving help through our customer support channels;

Participation in customer surveys or contests; and

Facilitation in the delivery of our Services and to respond to your

inquiries.

Sources of Personal Information

Learn more about the sources of personal information we collect in "WHAT

INFORMATION DO WE COLLECT?"

How We Use and Share Personal Information

Your personal information may be used in profiling and automated

processes that could produce legal or similarly significant effects for you.

Learn more about how we use your personal information in the section,

"HOW DO WE PROCESS YOUR INFORMATION?"

Will your information be shared with anyone else?

We may disclose your personal information with our service providers

pursuant to a written contract between us and each service provider. Learn

more about how we disclose personal information to in the section, "WHEN

AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"

We may use your personal information for our own business purposes,

such as for undertaking internal research for technological development

and demonstration. This is not considered to be "selling" of your personal

information.

We have not disclosed, sold, or shared any personal information to third

parties for a business or commercial purpose in the preceding twelve (12)

months. We will not sell or share personal information in the future

belonging to website visitors, users, and other consumers.

Your Rights

You have rights under certain US state data protection laws. However,

these rights are not absolute, and in certain cases, we may decline your

request as permitted by law. These rights include:

Right to know whether or not we are processing your personal data

Right to access your personal data

Right to correct inaccuracies in your personal data

Right to request the deletion of your personal data

Right to obtain a copy of the personal data you previously shared

with us

Right to non-discrimination for exercising your rights

Right to opt out of the processing of your personal data if it is used

for targeted advertising (or sharing as defined under California’s

privacy law), the sale of personal data, or profiling in furtherance of

decisions that produce legal or similarly significant effects

("profiling")

Depending upon the state where you live, you may also have the following

rights:

Right to access the categories of personal data being processed (as

permitted by applicable law, including Minnesota’s privacy law)

Right to obtain a list of the categories of third parties to which we

have disclosed personal data (as permitted by applicable law,

including California's and Delaware's privacy law)

Right to obtain a list of specific third parties to which we have

disclosed personal data (as permitted by applicable law, including

Minnesota's and Oregon's privacy law)

Right to review, understand, question, and correct how personal data

has been profiled (as permitted by applicable law, including

Minnesota’s privacy law)

Right to limit use and disclosure of sensitive personal data (as

permitted by applicable law, including California’s privacy law)

Right to opt out of the collection of sensitive data and personal data

collected through the operation of a voice or facial recognition

feature (as permitted by applicable law, including Florida’s privacy

law)

How to Exercise Your Rights

To exercise these rights, you can contact us by visiting tryforerunner.com,

by emailing us at yash@tryforeruner.com, by visiting tryforerunner.com, or

by referring to the contact details at the bottom of this document.

Under certain US state data protection laws, you can designate an

authorized agent to make a request on your behalf. We may deny a

request from an authorized agent that does not submit proof that they have

been validly authorized to act on your behalf in accordance with applicable

laws.

Request Verification

Upon receiving your request, we will need to verify your identity to

determine you are the same person about whom we have the information

in our system. We will only use personal information provided in your

request to verify your identity or authority to make the request. However, if

we cannot verify your identity from the information already maintained by

us, we may request that you provide additional information for the purposes

of verifying your identity and for security or fraud-prevention purposes.

If you submit the request through an authorized agent, we may need to

collect additional information to verify your identity before processing your

request and the agent will need to provide a written and signed permission

from you to submit such request on your behalf.

Appeals

Under certain US state data protection laws, if we decline to take action

regarding your request, you may appeal our decision by emailing us at

yash@tryforerunner.com. We will inform you in writing of any action taken

or not taken in response to the appeal, including a written explanation of

the reasons for the decisions. If your appeal is denied, you may submit a

complaint to your state attorney general.

California "Shine The Light" Law

California Civil Code Section 1798.83, also known as the "Shine The Light"

law, permits our users who are California residents to request and obtain

from us, once a year and free of charge, information about categories of

personal information (if any) we disclosed to third parties for direct

marketing purposes and the names and addresses of all third parties with

which we shared personal information in the immediately preceding

calendar year. If you are a California resident and would like to make such

a request, please submit your request in writing to us by using the contact

details provided in the section "HOW CAN YOU CONTACT US ABOUT

THIS NOTICE?"

10. DO WE MAKE UPDATES TO THIS NOTICE?

In Short: Yes, we will update this notice as necessary to stay compliant

with relevant laws.

We may update this Privacy Notice from time to time. The updated version

will be indicated by an updated "Revised" date at the top of this Privacy

Notice. If we make material changes to this Privacy Notice, we may notify

you either by prominently posting a notice of such changes or by directly

sending you a notification. We encourage you to review this Privacy Notice

frequently to be informed of how we are protecting your information.

11. HOW CAN YOU CONTACT US ABOUT THIS

NOTICE?

If you have questions or comments about this notice, you may email us at

yash@tryforerunner.com or contact us by post at:

Forerunner AI Inc.

__________

__________

12. HOW CAN YOU REVIEW, UPDATE, OR DELETE

THE DATA WE COLLECT FROM YOU?

Based on the applicable laws of your country or state of residence in the

US, you may have the right to request access to the personal information

we collect from you, details about how we have processed it, correct

inaccuracies, or delete your personal information. You may also have the

right to withdraw your consent to our processing of your personal

information. These rights may be limited in some circumstances by

applicable law. To request to review, update, or delete your personal

information, please visit: tryforerunner.com.


 `}   </div></div>
}