import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom';
import './index.css';

import { Analytics } from "@vercel/analytics/react"
import { AnimatePresence } from 'framer-motion';
import VideoDemo from './pages/VideoDemo';

import SplashScreen from './pages/SplashScreen';
import NotFound from './pages/NotFound';
import SVGLoading from './components/SVGLoading';
import ChatDemo from './pages/ChatDemo';
import Login from './pages/Login';
import TOS from './pages/TOS';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');

  // Page transition animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, [location]);

  // Password checking logic
  const handlePasswordSubmit = (input) => {
    if (input === 'aiengineer') {
      setIsAuthenticated(true);
      navigate(redirectPath);
    } else {
      alert('Incorrect password');
    }
  };

  const PasswordProtectedRoute = ({ element, path }) => {
    if (!isAuthenticated) {
      setRedirectPath(path);
    }
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <SVGLoading />
      ) : (
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/video-demo" element={<VideoDemo />} />
          <Route path="/chat-demo" element={<PasswordProtectedRoute path="/chat-demo" element={<ChatDemo />} />} />
          <Route path="/login" element={<Login onSubmit={handlePasswordSubmit} />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </AnimatePresence>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Analytics />
    <App />
  </Router>
);
